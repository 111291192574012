<template>
  <section class="py-20 flex items-center w-full overflow-hidden">
    <div class="w-[200%] flex">
      <div v-for="i in 4" :key="i" class="slide min-w-fit flex gap-4 items-center pr-[7rem] xl:pr-8">
        <img
          src="https://web-cdn.saudetrevo.com.br/images/ana.webp"
          alt="Ana"
          class="rounded-full size-14 border-[3px] border-base-50"
        />
        <p class="text-nowrap text-secondary-300 text-3xl font-sora font-bold">
          Afinal de contas, uma boa saúde
        </p>
        <img
          src="https://web-cdn.saudetrevo.com.br/images/adam.webp"
          alt="Adam"
          class="rounded-full size-14 border-[3px] border-base-50"
        />
        <p class="text-nowrap text-secondary-300 text-3xl font-sora font-bold inline-block">
          começa com boas escolhas!
        </p>
      </div>
    </div>
  </section>
</template>

<style scoped>
@keyframes slide {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

.slide {
  animation: slide 10s infinite linear;
  will-change: transform;
}
</style>
